import React from 'react';
import EPKBaseComponent from '../../EPKFoundation/EPKBaseComponent';
import { type } from '@testing-library/user-event/dist/type';

class EPKSocialMedia extends EPKBaseComponent {
  renderComponent(dynamicStyles) {
    let path = require(`./${this.props.type}.png`);

    let containerDynamicStyles = {};
    if (dynamicStyles.padding !== undefined) {
        containerDynamicStyles.padding = dynamicStyles.padding;
        dynamicStyles.padding = undefined
    }

    if (dynamicStyles.transform !== undefined) {
      containerDynamicStyles.transform = dynamicStyles.transform;
      dynamicStyles.transform = undefined;
    }

    let childrenContainerDynamicStyles = JSON.parse(JSON.stringify(dynamicStyles));
    if (dynamicStyles.borderColor !== undefined) { // quer dizer que tem RoundedRectangleBorder
      childrenContainerDynamicStyles.borderWidth = undefined;
      childrenContainerDynamicStyles.borderRadius = undefined;
      childrenContainerDynamicStyles.borderStyle = undefined;
      childrenContainerDynamicStyles.borderColor = undefined;
      childrenContainerDynamicStyles.display = undefined;
    }

    const backgroundColorMap = { backgroundColor: dynamicStyles["color"] };

    return (
      <div style={{ overflow: "visible", position: "relative", display: "inline-block", ...containerDynamicStyles }}>
        <div
          style={{
            overflow: "hidden",
            display: "inline-block",
            WebkitMaskImage: `url(${path})`, // For WebKit-based browsers (Safari, Chrome)
            WebkitMaskSize: "cover", // Adjusts mask to fit container
            maskImage: `url(${path})`, // Standard mask for other browsers
            maskSize: "cover",
            width: "100px", // Adjust to your shape's size
            height: "100px",
            ...dynamicStyles,
            ...backgroundColorMap
          }}
        ></div>
        <div style={{ overflow: "visible", position: "absolute", bottom: "0", left: "0", ...childrenContainerDynamicStyles }}>
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}

export default EPKSocialMedia;