import React from 'react';
import EPKBaseComponent from '../EPKFoundation/EPKBaseComponent';

class EPKSpacer extends EPKBaseComponent {
  renderComponent(dynamicStyles) {
    return <div style={{flex: "1", ...dynamicStyles }}>
        {this.renderChildren()}
    </div>;
  }
}

export default EPKSpacer;