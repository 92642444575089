export function applyFrame(dynamicStyles, attr, rule) {
    if (convertFrameSize(attr.width) !== undefined) {
        dynamicStyles["width"] = convertFrameSize(attr.width);
    }
    if (convertFrameSize(attr.height) !== undefined) {
        dynamicStyles["height"] = convertFrameSize(attr.height);
    }
    
	return dynamicStyles;
}

function convertFrameSize(amount) {
    const isMobile = window.innerWidth <= 768;

    switch (amount) {
        case 'tiny':
            return isMobile ? '25px' : '50px';
        case 'bannerHeight':
            return isMobile ? '200px' : '450px';
        case 'profile':
            return isMobile ? '100px' : '200px';
        case 'huge':
            return isMobile ? '400px' : '500px'; // Adjust for mobile
        case 'big':
            return isMobile ? '200px' : '250px';
        case 'medium':
            return isMobile ? '120px' : '150px';
        case 'small':
            return isMobile ? '80px' : '100px';
        case 'socialImgSmall':
            return isMobile ? '30px' : '60px';
        case 'socialImgBig':
            return isMobile ? '40px' : '80px';
        default:
            return undefined;   // Handle any unexpected cases
    }
}