export function applyPadding(dynamicStyles, attr, rule) {
    let top = convertPadding(attr.top);
    let bottom = convertPadding(attr.bottom);
    let leading = convertPadding(attr.leading);
    let trailing = convertPadding(attr.trailing);
    dynamicStyles["padding"] = `${top} ${trailing} ${bottom} ${leading}`;
	return dynamicStyles;
}

function convertPadding(level) {
    const isMobile = window.innerWidth <= 768;

    switch (level) {
        case "none":
            return "0";
        case "small":
            return !isMobile ? "2px" : "1px";
        case "base":
            return !isMobile ? "4px" : "2px";
        case "medium":
            return !isMobile ? "8px" : "3px";
        case "big":
            return !isMobile ? "16px" : "8px";
        case "twenty":
            return !isMobile ? "20px" : "5px";
        case "huge":
            return !isMobile ? "40px" : "18px";
        case "bannerHuge":
            return !isMobile ? "90px" : "55px";
        default:
            return "0"; // Default to none (0) if padding is not recognized
    }
}