import React from 'react';
import EPKBaseComponent from '../../../EPKFoundation/EPKBaseComponent';
import axios from 'axios'; // or any other method to fetch data

class EPKImage extends EPKBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      imageURL: '', // Holds the fetched image URL
    };
  }

  componentDidMount() {
    console.log(this.props.imageId);
    if (this.props.imageId) {
      const imageEndpoint = `https://dockerapi.bandbookr.uk/images/getImage/${this.props.imageId}`;
      
      // Fetch image URL from the API using fetch
      fetch(imageEndpoint)
        .then(response => response.json())
        .then(data => {
          if (data && data.imageUrl) {
            this.setState({ imageURL: data.imageUrl });
          }
        })
        .catch(error => {
          console.error("Error fetching image:", error);
        });
    }
  }

  renderComponent(dynamicStyles) {
    let containerDynamicStyles = {};
    if (dynamicStyles.padding !== undefined) {
        containerDynamicStyles.padding = dynamicStyles.padding;
        dynamicStyles.padding = undefined;
    }
    if (dynamicStyles.transform !== undefined) {
      containerDynamicStyles.transform = dynamicStyles.transform;
      dynamicStyles.transform = undefined;
    }

    let childrenContainerDynamicStyles = JSON.parse(JSON.stringify(dynamicStyles));
    if (dynamicStyles.borderColor !== undefined) {
      childrenContainerDynamicStyles.borderWidth = undefined;
      childrenContainerDynamicStyles.borderRadius = undefined;
      childrenContainerDynamicStyles.borderStyle = undefined;
      childrenContainerDynamicStyles.borderColor = undefined;
      childrenContainerDynamicStyles.display = undefined;
    }

    const imageSrc = this.state.imageURL || require('./placeholderImage.png');

    return (
      <div style={{ overflow: "visible", position: "relative", display: "inline-block", width: "100%", ...containerDynamicStyles }}>
        <img src={imageSrc} style={{ display: "block", width: "100%", ...dynamicStyles }} alt="EPK" />
        <div style={{ overflow: "visible", position: "absolute", bottom: "0", left: "0", width: "100%", height: "100%", ...childrenContainerDynamicStyles }}>
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}

export default EPKImage;