export function applyFontWeight(dynamicStyles, attr, rule) {
    let attribute = 'fontWeight';
	if (rule !== undefined) {
		attribute = rule;
	}
	dynamicStyles[attribute] = convertFontWeight(attr.weight);
	return dynamicStyles;
}

function convertFontWeight(swiftUIFontWeight) {
    switch (swiftUIFontWeight) {
        case "black":
            return "900";
        case "bold":
            return "700";
        case "heavy":
            return "800";
        case "light":
            return "300";
        case "medium":
            return "500";
        case "regular":
            return "400";
        case "semibold":
            return "600";
        case "thin":
            return "100";
        case "ultrathin":
            return "100";
        default:
            return "400"; // Default to regular (400) if weight is not recognized
    }
}
