import React from 'react';
import EPKBaseComponent from '../../EPKFoundation/EPKBaseComponent';
import './EPKRoot.css';

class EPKRoot extends EPKBaseComponent {

  ruleset = {
    
  }

  renderComponent(dynamicStyles) {
    let alignment = 'leading';

    const isMobile = window.innerWidth <= 768;

    return (
        <div className="parent" style={{ 
          display: "flex", 
          flexDirection: "column", 
          width: "100%", 
          gap: `${isMobile ? 18 : 24}px`, 
          alignItems: alignment, 
          justifyContent: "center", 
          ...dynamicStyles 
        }}>
          {this.renderChildren()}
        </div>
    );
  }
}

export default EPKRoot;