export function applyFont(dynamicStyles, attr, rule) {
    let fontSize = 'fontSize';
	dynamicStyles[fontSize] = convertToEm(attr.size);
    let fontType = 'fontFamily';
    dynamicStyles[fontType] = convertFontFamily(attr.type);
	return dynamicStyles;
}

function convertFontFamily(type) {
    switch (type) {
        case "Helvetica":
            return "Helvetica";
        case "Montserrat":
            return "Montserrat"
        case "ShadowsIntoLight":
            return "Shadows Into Light"
        default:
            return "Helvetica";
    }
}

function convertToEm(swiftUIFontSize) {
    let isMobile = window.innerWidth <= 768;
    let sizeMultiplier = isMobile ? 1 : 1.5;

    switch (swiftUIFontSize) {
        case "largeTitle":
            return `${2.5 * sizeMultiplier}em`;
        case "title1":
            return `${2 * sizeMultiplier}em`;
        case "title2":
            return `${1.5 * sizeMultiplier}em`;
        case "title3":
            return `${1.25 * sizeMultiplier}em`;
        case "body":
            return `${1 * sizeMultiplier}em`;
        case "subheadline":
            return `${0.875 * sizeMultiplier}em`;
        case "headline":
            return `${1 * sizeMultiplier}em`;
        case "footnote":
            return `${0.75 * sizeMultiplier}em`;
        case "caption2":
            return `${0.625 * sizeMultiplier}em`;
        case "caption1":
            return `${0.75 * sizeMultiplier}em`;
        case "callout":
            return `${0.875 * sizeMultiplier}em`;
        default:
            return `${1 * sizeMultiplier}em`; // Default to 1em if size is not recognized
    }
}