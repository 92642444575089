export function applyMultilineTextAlignment(dynamicStyles, attr, rule) {
    let attribute = 'textAlign';
	if (rule !== undefined) {
		attribute = rule;
	}
	dynamicStyles[attribute] = convertTextAlignment(attr.alignment);
	return dynamicStyles;
}

function convertTextAlignment(swiftUIAlignment) {
    switch (swiftUIAlignment) {
        case "leading":
            return "left";
        case "center":
            return "center";
        case "trailing":
            return "right";
        default:
            return "center";
    }
}
