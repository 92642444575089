import React from 'react';
import EPKBaseComponent from '../../EPKFoundation/EPKBaseComponent';

class EPKWrappingHStack extends EPKBaseComponent {

    ruleset = {
      
    }
  
    renderComponent(dynamicStyles) {
      return (
        <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "4px",
          height: "100%",
          width: "100%",
          alignItems: "start",
          justifyContent: "start",
          ...dynamicStyles}}>
          {this.renderChildren()}
        </div>
      );
    }
  }
  
  export default EPKWrappingHStack;