import React from 'react';
import { buildDynamicStyles } from './EPKDynamicStyles';
import EPKJsonRenderer from './EPKJsonRenderer';

class EPKBaseComponent extends React.Component {
  renderChildren() {
    const children = this.props.children;
    if (!children || !Array.isArray(children) || children.length === 0) {
      return null;
    }
    return children.map((child, index) => (
      <React.Fragment key={index}>
        <EPKJsonRenderer data={child} />
      </React.Fragment>
    ));
  }

  render() {
    const attributes = this.props.attributes;
    const dynamicStyles = buildDynamicStyles(attributes, this.ruleset, this.props.children);
    if (this.props.viewType !== undefined) {
      console.log(this.props.viewType);
      dynamicStyles['className'] = this.props.viewType;
    }
    return this.renderComponent(dynamicStyles);
  }
}

export default EPKBaseComponent;