import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EPKJsonRenderer from './EPKFoundation/EPKJsonRenderer';
import './EPKComponents/Stacks/EPKScrollView.css'

const App = () => {
  const { id } = useParams(); // Extract the id from the URL
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      // Construct the API endpoint using the id from the URL
      const apiEndpoint = `https://dockerapi.bandbookr.uk/portfolios/get/${id}`;

      fetch(apiEndpoint)
        .then(response => response.json())
        .then(data => {
          setJsonData(JSON.parse(data.jsonContent)); // Set jsonData with the jsonContent from the response
          setLoading(false); // Stop loading once data is fetched
        })
        .catch(error => {
          console.error("Error fetching portfolio data:", error);
          setLoading(false); // Stop loading in case of an error
        });
    }
  }, [id]); // Fetch data when the id changes

  return (
    <div className="scrollView" style={{ overflowY: "scroll", height: "100vh", width: "100vw", backgroundColor: "#1A1A1A" }}>
      {loading ? (
        <div style={{ color: "white" }}>Loading...</div> // Display loading message while fetching data
      ) : (
        <EPKJsonRenderer data={jsonData} />
      )}
    </div>
  );
};

export default App;