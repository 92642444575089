import React from 'react';
import EPKBaseComponent from '../EPKFoundation/EPKBaseComponent';
import ReactPlayer from 'react-player/youtube';

class EPKYoutubePlayer extends EPKBaseComponent {
  renderComponent(dynamicStyles) {
    return (
      <div
    style={{
      width: "100%",
      position: "relative",
      paddingBottom: "56.25%",
      borderRadius: "15px",
      overflow: "hidden",
      ... dynamicStyles
    }}
  >
    <ReactPlayer
      width="100%"
      height="100%"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: "15px" // Match the radius to the container
      }}
      pip={true}
      url={this.props.youtubeURL}
      controls={true}
    />
  </div>
    );
  }
}

export default EPKYoutubePlayer;