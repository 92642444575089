export function applyColor(dynamicStyles, attr, rule) {
    let attribute = 'color';
	if (rule !== undefined) {
		attribute = rule;
	}
	dynamicStyles[attribute] = `rgba(${getColor(attr)})`;
	return dynamicStyles;
}

function getColor(attr) {
    return `${attr.r * 255}, ${attr.g * 255}, ${attr.b * 255}, ${attr.a}`;
}