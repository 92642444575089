import React from 'react';
import EPKBaseComponent from '../EPKFoundation/EPKBaseComponent';

class EPKText extends EPKBaseComponent {

  ruleset = {
    
  } 

  renderComponent(dynamicStyles) {
    return <div style={{ whiteSpace: 'pre-line', ...dynamicStyles }}>
      {this.props.text.length === 0 ? this.props.editingLabel : this.props.text}
    </div>
  }
}

export default EPKText;