import React from 'react';
import EPKBaseComponent from '../../EPKFoundation/EPKBaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included

class EPKVerticalStack extends EPKBaseComponent {

  ruleset = {
    
  }

  renderComponent(dynamicStyles) {
    let alignment = 'center';
    if (this.props.horizontalAlignment !== undefined) {
      alignment = this.getBootstrapAlignment(this.props.horizontalAlignment);
    }

    return (
      <div
        className={this.props.viewType}
        style={{
          display: "flex",
          flexDirection: "column", /* Use row if you want horizontal layout */
          height: "100%", /* or any specific height */
          width: "100%",  /* or any specific width */
          boxSizing: "border-box" /* Include padding in the height/width calculation */
        }}
      >
      <div style={{display: "flex",
       flexDirection: "column",
        width: "100%",
        gap: "4px",
        alignItems: `${alignment}`,
        justifyContent: "center",
        overflow: "visible",
         ...dynamicStyles}
         }>
        {this.renderChildren()}
      </div>
      </div>
    );
  }

  getBootstrapAlignment(alignment) {
    switch (alignment) {
      case "leading":
        return "start";
      case "trailing":
        return "end";
      default:
        return "center";
    }
  }
}

export default EPKVerticalStack;