import React from 'react';
import EPKBaseComponent from '../../EPKFoundation/EPKBaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import './EPKScrollView.css';

class EPKScrollView extends EPKBaseComponent {
  
  renderComponent(dynamicStyles) {
    const direction = this.props.direction || 'vertical'; // Default to vertical if not specified

    return (
      <div
        className="scrollView"
        style={{
          display: "flex",
          flexDirection: direction === 'horizontal' ? 'row' : 'column',
          overflowX: direction === 'horizontal' ? 'auto' : 'hidden',
          overflowY: direction === 'vertical' ? 'auto' : 'hidden',
          height: '100%',
          width: '100%',
          ...dynamicStyles
        }}
      >
        {this.renderChildren()}
      </div>
    );
  }
}

export default EPKScrollView;