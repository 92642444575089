export function applyRoundedRectangleStroke(dynamicStyles, attr, rule) {
    let lineWidth = 'borderWidth';
    dynamicStyles[lineWidth] = `${attr.lineWidth}px`;

    const isMobile = window.innerWidth <= 768;
    const modifier = isMobile ? 1 : 1.8;
    let cornerRadius = 'borderRadius';
    dynamicStyles[cornerRadius] = `${attr.cornerRadius * modifier}px`;

    dynamicStyles['borderStyle'] = 'solid';

    let color = 'borderColor';
    dynamicStyles[color] = `rgba(${getColor(attr)})`;

    dynamicStyles['display'] = 'inline-block';

	return dynamicStyles;
}

function getColor(attr) {
    return `${attr.r * 255}, ${attr.g * 255}, ${attr.b * 255}, ${attr.a}`;
}