import React, { Children } from 'react';
import EPKBaseComponent from '../../EPKFoundation/EPKBaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included

class EPKZStack extends EPKBaseComponent {
  renderComponent(dynamicStyles) {
    // Prepare click handler if present
    const onClick = dynamicStyles['onClick'];
    dynamicStyles['onClick'] = undefined;

    return (
        this.renderChildren()
    );
  }
}

export default EPKZStack;