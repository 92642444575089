import { ClassNames } from "@emotion/react";

export function applyOnTapGesture(dynamicStyles, attr, rule, children) {
    const onTap = getTranslatedFunction(attr.function, children);
    if (onTap !== null && onTap !== undefined) {
        let attribute = 'onClick';
	    dynamicStyles[attribute] = onTap;
    }
    
	return dynamicStyles;
}

function getTranslatedFunction(name, children) {
    switch (name) {
      case "socialMedia":
        return () => {
            // if (children === undefined) { return null };
          let socialMediaComponent = epkQuerySelectorSocialMedia(children);
          switch (socialMediaComponent.type) {
            case "instagram":
              window.open(`https://www.instagram.com/${socialMediaComponent.endpoint}`);
              break;
            case "whatsapp":
              window.open(`https://wa.me/${socialMediaComponent.endpoint}`);
              break;
            case "email":
              window.location.href = `mailto:${socialMediaComponent.endpoint}`;
              break;
            case "xtwitter":
              window.open(`https://twitter.com/${socialMediaComponent.endpoint}`);
              break;
            case "facebook":
              window.open(`https://www.facebook.com/${socialMediaComponent.endpoint}`);
              break;
            case "phone":
              window.location.href = `tel:${socialMediaComponent.endpoint}`;
              break;
            case "spotify":
              window.open(`https://open.spotify.com/artist/${socialMediaComponent.endpoint}`);
              break;
          }
        //   window.open('https://www.facebook.com', '_blank');
        };
      default:
        return null;
    }
  }

  function epkQuerySelectorSocialMedia(children) {
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.endpoint !== undefined && child.type !== undefined) {
            return child;
        }
        const childResult = epkQuerySelectorSocialMedia(child.children);
        if (childResult !== null) {
            return childResult;
        }
    }
    return null;
  };