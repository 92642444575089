import React from 'react';
import EPKBaseComponent from '../EPKFoundation/EPKBaseComponent';

class EPKRectangle extends EPKBaseComponent {

  ruleset = {
    'color': 'backgroundColor'
  }

  renderComponent(dynamicStyles) {
    return <div style={{backgroundColor: 'black', ...dynamicStyles }}>
        {this.renderChildren()}
    </div>;
  }
}

export default EPKRectangle;