import { applyBackground } from "../EPKAttributes/EPKBackground";
import { applyBlur } from "../EPKAttributes/EPKBlur";
import { applyClipShapeRectangle } from "../EPKAttributes/Clipping/EPKClipShapeRectangle";
import { applyColor } from "../EPKAttributes/EPKColor";
import { applyPadding } from "../EPKAttributes/EPKPadding";
import { applyFont } from "../EPKAttributes/TextRelated/EPKFont";
import { applyFontWeight } from "../EPKAttributes/TextRelated/EPKFontWeight";
import { applyUnderline } from "../EPKAttributes/TextRelated/EPKUnderline";
import { applyScaleToFill } from "../EPKAttributes/EPKScaleToFill";
import { applyScaleToFit } from "../EPKAttributes/EPKScaleToFit";
import { applyClipShapeCircle } from "../EPKAttributes/Clipping/EPKClipShapeCircle";
import { applyFrameMaxWidth } from "../EPKAttributes/Framing/EPKFrameMaxWidth";
import { applyFrame } from "../EPKAttributes/Framing/EPKFrame";
import { applyOffset } from "../EPKAttributes/EPKOffset";
import { applyRoundedRectangleStroke } from "../EPKAttributes/EPKRoundedRectangleStroke";
import { applyMultilineTextAlignment } from "../EPKAttributes/TextRelated/EPKMultilineTextAlignment";
import { applyLineLimit } from "../EPKAttributes/TextRelated/EPKLineLimit";
import { applyShadow } from "../EPKAttributes/EPKShadow";
import { applyOnTapGesture } from "../EPKAttributes/EPKOnTapGesture";

// ruleset is just a dict of attributeName -> css 
// attribute that each component can have, to 
// especify how specifically that component should 
// handle some attribute.
export function buildDynamicStyles(attributes, ruleset, children) {
	const dynamicStyles = {};

	attributes.forEach(attr => {
		let rule;
		if (ruleset !== undefined) {
			rule = ruleset[attr.attributeName];
		}

		switch (attr.attributeName) {
			case 'color':
				applyColor(dynamicStyles, attr, rule);
				break;
			case 'background':
				applyBackground(dynamicStyles, attr, rule);
				break;
			case 'underline':
				applyUnderline(dynamicStyles, attr, rule);
				break;
			case 'font':
				applyFont(dynamicStyles, attr, rule);
				break;
			case 'fontWeight':
				applyFontWeight(dynamicStyles, attr, rule);
				break;
			case 'padding':
				applyPadding(dynamicStyles, attr, rule);
				break;
			case 'clipShapeRectangle':
				applyClipShapeRectangle(dynamicStyles, attr, rule);
				break;
			case 'clipShapeCircle':
				applyClipShapeCircle(dynamicStyles, attr, rule);
				break;
			case 'blur':
				applyBlur(dynamicStyles, attr, rule);
				break;
			case 'scaledToFill':
				applyScaleToFill(dynamicStyles, attr, rule);
				break;
			case 'scaledToFit':
				applyScaleToFit(dynamicStyles, attr, rule);
				break;
			case 'frameMaxWidth':
				applyFrameMaxWidth(dynamicStyles, attr, rule);
				break;
			case 'frame':
				applyFrame(dynamicStyles, attr, rule);
				break;
			case 'offset':
				applyOffset(dynamicStyles, attr, rule);
				break;
			case 'roundedRectangleStroke':
				applyRoundedRectangleStroke(dynamicStyles, attr, rule);
				break;
			case 'multilineTextAlignment':
				applyMultilineTextAlignment(dynamicStyles, attr, rule);
				break;
			case 'lineLimit':
				applyLineLimit(dynamicStyles, attr, rule);
				break;
			case 'shadow':
				applyShadow(dynamicStyles, attr, rule);
				break;
			case 'onTapGesture':
				applyOnTapGesture(dynamicStyles, attr, rule, children);
				break;
			default:
				break;
		}
	});

	return dynamicStyles;
}