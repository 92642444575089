export function applyOffset(dynamicStyles, attr, rule) {
    let attribute = 'transform';
	if (rule !== undefined) {
		attribute = rule;
	}
    let width = 0;
    let height = 0;
    if (convertOffset(attr.width) !== undefined) {
        width = convertOffset(attr.width);
    }
    if (convertOffset(attr.height) !== undefined) {
        height = convertOffset(attr.height);
    }
	dynamicStyles[attribute] = `translate(${width}px, ${height}px)`;
	return dynamicStyles;
}

function convertOffset(amount) {
    const isMobile = window.innerWidth <= 768;

    switch (amount) {
        case 'huge':
            return 250;
        case 'big':
            return 125;
        case 'medium':
            return isMobile ? 150 : 325;
        case 'small':
            return 50;
        default:
            return undefined;   // Handle any unexpected cases
    }
}