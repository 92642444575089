import React from 'react';
import EPKBaseComponent from '../../../EPKFoundation/EPKBaseComponent';

class EPKSFSymbol extends EPKBaseComponent {
  renderComponent(dynamicStyles) {
    let path = require(`./${this.props.systemName}.png`);

    // Conditionally include 'padding' in containerDynamicStyles
    let containerDynamicStyles = {};
    if (dynamicStyles.padding !== undefined) {
        containerDynamicStyles.padding = dynamicStyles.padding;
        dynamicStyles.padding = undefined
    }
    if (dynamicStyles.transform !== undefined) {
      containerDynamicStyles.transform = dynamicStyles.transform;
      dynamicStyles.transform = undefined;
    }

    let childrenContainerDynamicStyles = JSON.parse(JSON.stringify(dynamicStyles));
    if (dynamicStyles.borderColor !== undefined) { // quer dizer que tem RoundedRectangleBorder
      childrenContainerDynamicStyles.borderWidth = undefined;
      childrenContainerDynamicStyles.borderRadius = undefined;
      childrenContainerDynamicStyles.borderStyle = undefined;
      childrenContainerDynamicStyles.borderColor = undefined;
      childrenContainerDynamicStyles.display = undefined;
    }

    return (
      <div style={{ overflow: "visible", position: "relative", display: "inline-block", ...containerDynamicStyles }}>
        <img src={path} style={{zdisplay: "block", width: "100%", ...dynamicStyles}}></img>
        <div style={{ overflow: "visible", position: "absolute", bottom: "0", left: "0", width: "100%", height: "100%", ...childrenContainerDynamicStyles }}>
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}

export default EPKSFSymbol;