export function applyClipShapeRectangle(dynamicStyles, attr, rule) {
    let attribute = 'borderRadius';

    const isMobile = window.innerWidth <= 768;
    const modifier = isMobile ? 0.8 : 1.2;

	let topLeft = attr.topLeft * modifier;
    let topRight = attr.topRight * modifier;
    let bottomLeft = attr.bottomLeft * modifier;
    let bottomRight = attr.bottomRight * modifier;
	dynamicStyles[attribute] = `${topLeft}px ${topRight}px ${bottomRight}px ${bottomLeft}px`;

	return dynamicStyles;
}